import React from 'react';
//import '../styles/LandingPage.css';
import Header from '../components/general/Header.js'
import Footer from '../components/general/Footer.js'


const ContactPage = () => {
    return (
        <div>
            <Header />
            <h1> Contact Page </h1>
            <Footer />
        </div>
    );
}

export default ContactPage;
